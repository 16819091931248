<template>
    <div class="record">
        <headbar :title="$t('销毁记录')"></headbar>

        <div class="card">
            <div class="card-title">
                <div class="card-title-value">{{ $t('销毁') }}LBD</div>
                <div class="card-title-number">{{ data.burnPool }}</div>
            </div>
            <img class="card-img" src="@/assets/img/logo.png" />
        </div>

        <div>{{ $t('变动明细') }}</div>

        <div class="table">
            <div class="table-title">
                <div class="table-title-item c1">{{ $t('类型') }}</div>
                <div class="table-title-item c2">LBD</div>
                <div class="table-title-item c3">{{ $t('时间') }}</div>
            </div>

            <div class="table-list">
                <van-list v-if="data.list.length > 0" v-model:loading="loading" :finished="finished"
                    :immediate-check="false" offset="10" :finished-text="$t('没有更多了')" @load="onLoad">
                    <div class="table-list-item" v-for="(item, index) in data.list" :key="index">
                        <div class="c1">{{ item.title }}</div>
                        <div class="c2">{{ item.burn }}</div>
                        <div class="c3">{{ item.createTime }}</div>
                    </div>
                </van-list>
            </div>

            <empty :text="$t('暂无记录')" v-if="data.list.length == 0" />
        </div>
    </div>
</template>
<script>
import { Empty, List } from 'vant';
import CustomComponent from "@/components/CustomComponent";

export default {
    name: '',
    components: {
        [Empty.name]: Empty,
        [List.name]: List,
    },
    data() {
        return {
            loading: false,
            finished: false,
            data: {
                lbdPool: 0,
                list: [],
            },
            pageIndex: 1
        }
    },
    created() {
        this.getData()
    },
    methods: {
        onLoad() {
            this.loading = true
            this.pageIndex++
            this.getData()
        },
        getData() {
            let _this = this
            _this.$request.post(
                "api/TokenZhubi/PublicList",
                {
                    pageIndex: _this.pageIndex,
                    pageSize: 20,
                },
                (res) => {
                    if (res.data.code == 0) {
                        _this.$q.dialog({
                            component: CustomComponent,
                            componentProps: {
                                messages: [res.data.msg],
                                persistent: true,
                            }
                        });
                        return
                    }
                    let data = res.data.data
                    if (data.list.length == 0) {
                        _this.finished = true
                    } else {
                        _this.finished = false
                    }
                    _this.data.lbdPool = data.lbdPool
                    _this.data.list.push(...data.list)
                    _this.loading = false
                }
            )
        },
    }
}
</script>
<style scoped>
.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background: #2C2050;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
}

.card-title {}

.card-title-number {
    margin-top: 10px;
    font-size: 22px;
}

.card-img {
    width: 60px;
    margin-right: 10px;
}

.table {
    margin: 10px;
    padding: 10px;
    border: 1px solid #352D41;
    color: #fff;
    background: #1F1828;
    border-radius: 10px;
}

.table-title,
.table-list-item {
    display: flex;
    align-items: center;
}

.table-title-item {
    font-size: 12px;
    color: #868080;
}

.table-title {
    margin-bottom: 10px;
}

.table-list-item {
    padding-bottom: 5px;
    margin-bottom: 8px;
    border-bottom: 1px solid #3F3848;
}

.c1 {
    flex: 1;
}

.c2 {
    text-align: center;
    flex: 1;
}

.c3 {
    text-align: right;
    flex: 1;
}
</style>